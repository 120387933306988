<template>
  <el-card class="box-card" style="margin-top: 30px">
    <template #header>
      <div class="card-header tododaytitle">
        <h4>快捷操作</h4>
      </div>
    </template>
    <div class="flexbox">
      <div class="flexitem" @click="pushto(0)">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shangpin"></use>
          </svg>
        </div>
        <div class="linkfont">上架商品</div>
      </div>
      <div class="flexitem" @click="pushto(1)">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-tuangoumiaosha"></use>
          </svg>
        </div>
        <div class="linkfont">团购管理</div>
      </div>
      <div class="flexitem" @click="pushto(2)">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-dingdan1"></use>
          </svg>
        </div>
        <div class="linkfont">订单管理</div>
      </div>
      <div class="flexitem" @click="pushto(3)">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-tubiaozhizuomoban"></use>
          </svg>
        </div>
        <div class="linkfont">课程管理</div>
      </div>
      <div class="flexitem" @click="pushto(4)">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-examine"></use>
          </svg>
        </div>
        <div class="linkfont">审批查阅</div>
      </div>
      <div class="flexitem" @click="pushto(5)">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-yuyue"></use>
          </svg>
        </div>
        <div class="linkfont">专家预约</div>
      </div>
    </div>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    pushto(index) {
      if (index === 0) {
        window.sessionStorage.setItem('AsideActiveLi', '2')
        this.$store.commit('setAsideActiveLi', '2')
        this.$router.push('/upgoodslist')
      } else if (index === 1) {
        window.sessionStorage.setItem('AsideActiveLi', '3')
        this.$store.commit('setAsideActiveLi', '3')
        this.$router.push('/teaminfo')
      } else if (index === 2) {
        window.sessionStorage.setItem('AsideActiveLi', '11')
        this.$store.commit('setAsideActiveLi', '11')
        this.$router.push('/orderform')
      } else if (index === 3) {
        window.sessionStorage.setItem('AsideActiveLi', '6')
        this.$store.commit('setAsideActiveLi', '6')
        this.$router.push('/courselist')
      } else if (index === 4) {
        window.sessionStorage.setItem('AsideActiveLi', '10')
        this.$store.commit('setAsideActiveLi', '10')
        this.$router.push('/authorzationmange')
      } else if (index === 5) {
        window.sessionStorage.setItem('AsideActiveLi', '7')
        this.$store.commit('setAsideActiveLi', '7')
        this.$router.push('/premange')
      }
    }
  }
}
</script>
<style scoped>
.tododaytitle {
  line-height: 0;
  padding: unset;
  text-align: left;
}
.flexbox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.flexitem {
  width: 23%;
  margin-left: 1%;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  cursor: pointer;
}
.font {
  font-size: 24px;
  vertical-align: baseline;
}
.linkfont {
  height: 28px;
  line-height: 28px;
  margin-left: 8px;
  font-weight: 500;
}
.linkfont:hover {
  color: #1890ff;
}
</style>
