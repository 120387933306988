<template>
  <el-container class="homebox">
    <el-aside
      :width="isCollapse ? '80px' : '160px'"
      style="text-align: left； overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;z-index:2"
    >
      <h4
        :style="
          isCollapse
            ? 'width:80px;transition:  width 0.28s; border-color .3s,background-color .3s,color .3s;'
            : 'width:160px;transition:  width 0.28s; border-color .3s,background-color .3s,color .3s;'
        "
        class="title"
      >
        <span> 后台管理系统</span>
      </h4>

      <el-menu
        default-active="1"
        style="font-size: 14px"
        class="el-menu-vertical-demo"
        background-color="#001529"
        text-color="#fff"
        active-text-color="#fff"
        :collapse="isCollapse"
        :collapse-transition="true"
        router
      >
        <el-menu-item
          @click="test('0')"
          :class="currentAsideIndex === '0' ? 'currentli' : ''"
          index="/index"
        >
          <i
            class="el-icon-location"
            :style="currentAsideIndex === '0' ? 'color:unset !important' : ''"
          ></i>
          <template #title>首页</template>
        </el-menu-item>
        <el-menu-item
          @click="test('1')"
          :class="currentAsideIndex === '1' ? 'currentli' : ''"
          index="/goods"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '1' ? 'color:unset !important' : ''"
            >&#xe602;</i
          >
          <template #title>商品管理</template>
        </el-menu-item>
        <el-menu-item
          @click="test('2')"
          :class="currentAsideIndex === '2' ? 'currentli' : ''"
          index="/upgoods"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '2' ? 'color:unset !important' : ''"
            >&#xe642;</i
          >
          <template #title>上架管理</template>
        </el-menu-item>
        <el-menu-item
          index="/coursemange"
          @click="test('3')"
          :class="currentAsideIndex === '3' ? 'currentli' : ''"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '3' ? 'color:unset !important' : ''"
            >&#xe693;</i
          >
          <template #title> 课程管理 </template></el-menu-item
        >
        <el-menu-item
          index="/newsmange"
          @click="test('4')"
          :class="currentAsideIndex === '4' ? 'currentli' : ''"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '4' ? 'color:unset !important' : ''"
            >&#xe623;</i
          >
          <template #title> 资讯管理 </template></el-menu-item
        >
        <el-menu-item
          @click="test('5')"
          :class="currentAsideIndex === '5' ? 'currentli' : ''"
          index="/teammange"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '5' ? 'color:unset !important' : ''"
            >&#xe625;</i
          >
          <template #title>活动管理</template>
        </el-menu-item>

        <el-menu-item
          index="/swiper"
          @click="test('6')"
          :class="currentAsideIndex === '6' ? 'currentli' : ''"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '6' ? 'color:unset !important' : ''"
            >&#xe61e;</i
          >
          <template #title> 首页管理 </template></el-menu-item
        >

        <el-menu-item
          @click="test('7')"
          :class="currentAsideIndex === '7' ? 'currentli' : ''"
          index="/professionmange"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '7' ? 'color:unset !important' : ''"
            >&#xe734;</i
          >
          <template #title>专家管理</template>
        </el-menu-item>
        <el-menu-item
          index="/chairmanmange"
          @click="test('8')"
          :class="currentAsideIndex === '8' ? 'currentli' : ''"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '8' ? 'color:unset !important' : ''"
            >&#xe612;</i
          >
          <template #title> 团长管理 </template></el-menu-item
        >

        <el-menu-item
          @click="test('9')"
          :class="currentAsideIndex === '9' ? 'currentli' : ''"
          index="/authorzationmange"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '9' ? 'color:unset !important' : ''"
            >&#xe736;</i
          >
          <template #title>审批管理</template>
        </el-menu-item>
        <el-menu-item
          @click="test('10')"
          :class="currentAsideIndex === '10' ? 'currentli' : ''"
          index="/paymange"
          v-if="userInfo.userType === '0' || userInfo.userType === '2'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '10' ? 'color:unset !important' : ''"
            >&#xed25;</i
          >
          <template #title>财务管理</template>
        </el-menu-item>
        <el-menu-item
          @click="test('11')"
          :class="currentAsideIndex === '11' ? 'currentli' : ''"
          index="/orderform"
          v-if="userInfo.userType === '0' || userInfo.userType === '1'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '11' ? 'color:unset !important' : ''"
            >&#xe632;</i
          >
          <template #title>订单管理</template>
        </el-menu-item>
        <el-menu-item
          index="/basemange"
          @click="test('12')"
          :class="currentAsideIndex === '12' ? 'currentli' : ''"
          v-if="userInfo.userType === '0'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '12' ? 'color:unset !important' : ''"
            >&#xe61d;</i
          >
          <template #title> 基地管理 </template></el-menu-item
        >
        <el-menu-item
          index="/aboutusmange"
          @click="test('13')"
          :class="currentAsideIndex === '13' ? 'currentli' : ''"
          v-if="userInfo.userType === '0'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '13' ? 'color:unset !important' : ''"
            >&#xe64f;</i
          >
          <template #title> 关于我们 </template></el-menu-item
        >
        <el-menu-item
          index="/systommange"
          @click="test('14')"
          :class="currentAsideIndex === '14' ? 'currentli' : ''"
          v-if="userInfo.userType === '0'"
        >
          <i
            class="iconfont"
            style="
              margin-right: 11px;
              vertical-align: baseline;
              font-size: 18px;
            "
            :style="currentAsideIndex === '14' ? 'color:unset !important' : ''"
            >&#xe64f;</i
          >
          <template #title> 系统管理 </template></el-menu-item
        >
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div class="headerbox">
          <div class="leftbox">
            <div>
              <i
                :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                style="font-size: 18px; cursor: pointer"
                @click="changeIsColl"
              ></i>
            </div>
            <div style="margin-left: 15px; margin-top: 3px">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item
                  :to="{ path: item.index }"
                  v-for="(item, index) in BreadInfo"
                  :key="index"
                  >{{ item.name }}</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
          </div>

          <el-dropdown @command="commandclick">
            <div class="currsor">
              <i
                class="el-icon-cpu"
                style="font-weight: 700"
                v-if="userInfo.userType === '0'"
              >
                <span class="namebox">{{ userInfo.userName }}</span></i
              >
              <i
                class="el-icon-user"
                style="font-weight: 700"
                v-else-if="userInfo.userType === '1'"
              >
                <span class="namebox">{{ userInfo.userName }}</span>
              </i>
              <i
                class="el-icon-coordinate"
                style="font-weight: 700"
                v-else-if="userInfo.userType === '2'"
              >
                <span class="namebox">{{ userInfo.userName }}</span></i
              >
              <i
                class="el-icon-setting"
                style="font-weight: 700"
                v-else-if="userInfo.userType === '3'"
              >
                <span class="namebox">{{ userInfo.userName }}</span></i
              >
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="1">修改密码</el-dropdown-item>
                <el-dropdown-item command="2">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main style="margin-top: 60px">
        <router-view />
      </el-main>
    </el-container>
    <el-dialog title="修改密码" v-model="dialogVisible" width="400px">
      <el-form :model="form" class="demo-form-inline">
        <el-form-item label="原密码">
          <div style="display: flex; justify-content: flex-end">
            <el-input
              v-model="form.oldPwd"
              clearable
              style="width: 290px"
              placeholder="请输入原密码"
              show-password
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="新密码">
          <div style="display: flex; justify-content: flex-end">
            <el-input
              v-model="form.newPwd"
              clearable
              style="width: 290px"
              placeholder="请输入新密码"
              show-password
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="确认密码">
          <div style="display: flex; justify-content: flex-end">
            <el-input
              v-model="form.confirmPwd"
              clearable
              style="width: 290px"
              placeholder="请再次输入新密码"
              show-password
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitPwd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-container>
</template>
<script>
import { onMounted } from 'vue'
export default {
  setup() {
    onMounted(
      () => {
        document.querySelector('.homebox').style.minHeight = window.innerHeight + 'px'
        document.querySelector('.el-menu').style.height = window.innerHeight - 60 + 'px'
        document.querySelector('.el-header').style.width = window.innerWidth - 160 + 'px'
        document.querySelector('.el-main').style.height = window.innerHeight - 60 + 'px'
        window.onresize = () => {
          return (() => {
            document.querySelector('.homebox').style.minHeight = window.innerHeight + 'px'
            document.querySelector('.el-menu').style.height = window.innerHeight - 60 + 'px'
            document.querySelector('.el-header').style.width = window.innerWidth - 160 + 'px'
            document.querySelector('.el-main').style.height = window.innerHeight - 60 + 'px'
          })()
        }
      }
    )
  },
  data() {
    return {
      currentAsideIndex: window.sessionStorage.getItem('AsideActiveLi') || '0',
      isCollapse: false,
      dialogVisible: false,
      form: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: ''
      }
    }
  },
  watch: {
    AsideActiveLi(n, o) {
      this.currentAsideIndex = n
    }
  },
  mounted() {
    // this.$store.commit('seteditorConfig', {
    //   // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
    //     UEDITOR_HOME_URL: '/UEditor/',
    //     // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    //     serverUrl: 'http://nktdec.ctvideo.cn/ueditor/jsp/controller.jsp',
    //     initialFrameHeight: 500,
    //     // imageMaxSize: 102400000,
    //     // imageCompressBorder: 1600,
    //     autoHeightEnabled: false,
    //     catchRemoteImageEnable: false,
    //     // imageCompressEnable: false,
    //     // imageScaleEnabled: false
    //     })
  },
  computed: {
    BreadInfo() {
      return this.$store.state.BreadInfo
    },
    AsideActiveLi() {
      return this.$store.state.AsideActiveLi
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    test(info) {
      window.sessionStorage.setItem('AsideActiveLi', info)
      this.$store.commit('setAsideActiveLi', info)
      this.currentAsideIndex = info
    },
    async submitPwd() {
      const res = await this.$http.post('/nktdec/cruserinfo/password/' + this.userInfo.userId, this.form)
      if (res.data.code !== '1') {
        this.$message.error(res.data.message)
      } else {
        this.$message.success('修改成功')
      }
      this.dialogVisible = false
    },
    commandclick(command) {
      if (command === '1') {
        this.dialogVisible = true
      } else if (command === '2') {
        window.sessionStorage.clear()
        this.$router.push('/login')
      }
    },
    changeIsColl() {
      this.isCollapse = !this.isCollapse
    }
  },
  components: {
  }
}
</script>
<style scoped>
.namebox {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 600;
}
.el-aside {
  background-color: #001529;
  transition: width 0.28s;
  overflow: hidden;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  /* line-height: 160px; */
}
.homebox {
  min-height: 700px;
}
.currentli {
  background-color: #1890ff !important;
}
.headerbox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  height: unset !important;
}
.el-menu {
  border-right: unset;
}
.leftbox {
  display: flex;
  justify-content: flex-start;
}
.title {
  height: 60px;
  line-height: 60px;
  margin: 0;
  color: #fff;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-left: 18px; */
  background-color: rgb(0, 21, 41);
}
.currsor {
  cursor: pointer;
}
</style>
